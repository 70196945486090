.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgb(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    position: relative;
    min-width: 300px;
    min-height: 100px;
    border-radius: 25px;
    background: white;
    padding: 25px;
    max-width: 400px;
}

.modal_header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(128 128 128 / 20%);
    padding-bottom: 15px;
    line-height: 160%;
}

.modal_header img {
    width: 12px;
    cursor: pointer;
}

.modal_header div {
    margin-left: 15px;
}

.button {
    background: red;
}

.button_row {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.button_row div {
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #0000001f;
    padding: 7px 10px;
    border-radius: 15px;
    min-width: 75px;
    text-align: center;
    font-weight: 500;
    color: white;
}

.success {
    background: linear-gradient(to top, rgb(40 167 69), rgb(65 185 92));
}

.danger {
    background: linear-gradient(to top, rgb(203, 45, 62), rgb(239, 71, 58));
}