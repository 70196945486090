.card {
    max-height: calc(100vh - 165px);
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    background: white;
}

.cardWrapper {
    position: relative;
}

.card_time {
    font-size: 38px;
    font-weight: 500;
}

.card_info {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    line-height: 150%;
    height: auto;
}

.guest {
    word-wrap: break-word;
    width: 195px;
}

.table_guest {
    display: flex;
}

.card_content {
    padding: 25px;
    background: white;
    border-radius: 25px 0px;
    margin-top: -25px;
    overflow-y: auto;
    max-height: calc(100vh - 310px);
}

.card_content img {
    width: 15px;
    margin-left: 15px;
}

.card_content div:last-child {
    padding: 0px;
    margin: 0px;
    border-bottom: 0px;
}

.card_content > div {
    margin-bottom: 10px;
    line-height: 170%;
    border-bottom: 1px solid #8080804a;
    padding-bottom: 5px;
    cursor: pointer;
}

.card_content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
    border-radius: 15px;
    background-color: #F5F5F5;
}

.card_content::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.card_content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.34);
    background-color: #555;
}

.card_content div span:first-child {
    font-weight: 600;
    margin-right: 5px;
}

.done {
    color: #9cd476;
    font-weight: 500;
}

.done_img {
    width: 20px;
    margin-left: 5px;
}

.comment {
  font-size: 13px;
  position: absolute;
  right: 1rem;
  top: 80px;
  padding: .1rem 1.5rem;
  border-radius: 1rem;
  z-index: 123;
  color: #fff;
  background-color: #ff6500;
  font-weight: 500;
  cursor: pointer;
}

.commentWrapper {
    position: absolute;
    right: 0;
    left: 0;
    top: 117px;
    background-color: #ff6500;
    color: white;
    width: 100%;
    font-size: 16px;
    border-radius: 1rem;
    padding: 1rem;
    height: max-content;
    z-index: 99;
    overflow: auto;
    max-height: 190px;
    display: block;
}

.commentNumber {
    font-size: 15px;
}

.commentItem {
    padding: 0.2rem 0;
}

.commentWrapper::-webkit-scrollbar, .commentWrapper div::-webkit-scrollbar, .commentWrapper ul::-webkit-scrollbar {
    background-color: rgba(238, 238, 238, 0.562);
    width: .4rem;
    height: .4rem;
  }
  
.commentWrapper::-webkit-scrollbar-thumb, .commentWrapper div::-webkit-scrollbar-thumb, .commentWrapper ul::-webkit-scrollbar-thumb {
background-color: #fff;
border-radius: 1rem;
}

.delivery {
    width: fit-content;
    display: block;
}

.card_info_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}