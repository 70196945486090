* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
}

html {
    overflow: hidden;
}

body {
    margin: 0;
    background: #edeef0;
    font-family: 'Montserrat', sans-serif;
    overflow: hidden;
    font-size: 16px;
}

h2 {
    font-size: 2em;
}

button, a {
    cursor: pointer;
}

.slick-list {
    padding: 40px 0px;
    margin: 0px 60px;
    overflow: visible;
}

.slick-slide > div {
    padding: 0px 7.5px;
}

.slick-next, .slick-prev {
    width: auto;
    height: auto;
    padding: 7.5px !important;
    z-index: 10;
}
.slick-arrow {
    background: #8b888899;
    padding: 5px;
    border-radius: 50%;
}
.slick-arrow:hover {
    background: rgb(123 123 123);
}

.slick-next img, .slick-prev img {
    width: 20px;
}

.slick-next {
    right: 15px;
}

.slick-prev {
    left: 15px;
}

.slick-prev img {
    transform: rotate(180deg);

}

.slick-prev:before,
.slick-next:before, [dir=rtl] .slick-prev:before {
    display: none;
}

.card_header {
    display: flex;
    justify-content: space-between;
    padding: 25px;
    color: white;
    height: auto;
}

.primary {
    background: #5e5e5ed1;
}

.warning {
    background: linear-gradient(to top, rgb(255, 183, 94), rgb(237, 143, 3));
}

.danger {
    background: linear-gradient(to top, rgb(203, 45, 62), rgb(239, 71, 58))
}

.text-center {
    text-align: center;
}

.finished {
    display: flex;
    justify-content: space-between;
    color: rgb(40 167 69);
    cursor: default !important;
}

.slick-disabled {
    display: none;
}


@media (max-width: 1440px) {
    body {
        font-size: 14px;
    }
    h2 {
        font-size: 1.8em;
    }
}

@media (max-width: 767px) {
    body {
        font-size: 12px;
    }
    h2 {
        font-size: 1.4em;
    }
    .slick-list {
        padding: 25px 0px;
        margin: 0px 15px;
    }
}