.button_row {
    /* margin-top: 15px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.button_row div {
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #0000001f;
    /* padding: 7px 10px; */
    border-radius: 15px;
    min-width: 75px;
    text-align: center;
    font-weight: 500;
    color: white;
}

.success {
    background: linear-gradient(to top, rgb(40 167 69), rgb(65 185 92));
}

.danger {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -.3rem;
    right: -.3rem;
    min-width: 2rem !important;
    height: 2rem;
    color: #000 !important;
    font-weight: 600;
    border: 3px solid #edeef0 !important;
    background: linear-gradient(to top, rgb(255, 255, 255), rgb(255, 255, 255));
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}