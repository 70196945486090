.wrapper {
    width: 100%;
    height: 65px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 25px;
    color: #ff6500;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

.logo_block {
    display: flex;
    align-items: center;
}

.logo_block .logo {
    margin-top: 40px;
    margin-right: 50px;
}

.logo {
    width: 70px;
    height: auto;
}

.button_block {
    display: flex;
    align-items: center;
}

.button_block button {
    border: none;
    background: none;
    margin-left: 60px;
}

.button_block img {
    width: 45px;
}

@media (max-width: 1440px) {
    .wrapper {
        padding: 0px 15px;
    }
    .logo_block img {
        margin-top: 0px;
        margin-right: 10px;
        width: 55px;
    }
    .button_block img {
        width: 25px;
    }
    .button_block button {
        border: none;
        background: none;
        margin-left: 15px;
    }
}

.version {
    color: #000;
    font-size: 14px;
    position: absolute;
    bottom: .2rem;
    right: .2rem;
}